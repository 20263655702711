import React, { useCallback, useContext, useEffect, useState } from "react";
import phone from "phone";
import { DataContext } from "../dataContext";
import { useLocation } from "react-router-dom";
import useApi from "../api/api";
import { ReactComponent as PayPalIcon } from "../assets/ico_paypal.svg";
import { ReactComponent as VenmoIcon } from "../assets/ico_venmo.svg";
import { ReactComponent as CardIcon } from "../assets/ico_card.svg";
import { ReactComponent as BankIcon } from "../assets/ico_bank.svg";
import { ReactComponent as GiftCardIcon } from "../assets/ico_gift_card.svg";
import FiBranding from "../theme/branding";

export const ExternalTransfer = () => {
  const api = useApi();
  const { transferData, setTransferData } = useContext(DataContext);
  const { branding } = FiBranding();

  const location = useLocation();

  const transferId = location.search.split("?t=")[1].split("&i=")[0];
  const inviteId = location.search.split("&i=")[1];

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorIdentifier, setErrorIdentifier] = useState("");
  const [errorBankAccount, setErrorBankAccount] = useState(false);
  const [errorBankRouting, setErrorBankRouting] = useState(false);
  const [paymentType, setPaymentType] = useState("");

  const [recipientType, setRecipientType] = useState("");
  const [recipientIdentifier, setRecipientIdentifier] = useState("");
  const [recipientAccountDetails, setRecipientAccountDetails] = useState({
    routing_number: "",
    account_number: "",
  });
  const [statusDate, setStatusDate] = useState(new Date());
  const [viewState, setViewState] = useState("");
  const [frameHeight, setFrameHeight] = useState("530px");

  useEffect(() => {
    setRecipientIdentifier(transferData.recipient_identifier);
    setRecipientType(transferData.recipient_type);
  }, [transferData]);

  const handleRecipientIdentifierChange = ({ target: { value } }) => {
    setRecipientIdentifier(value ? value : "");
  };

  const handleRecipientAccountDetailsChange = ({ target: { id, value } }) => {
    switch (id) {
      case "routing_number":
        if (value.length > 12) {
          return;
        } else {
          setRecipientAccountDetails({
            ...recipientAccountDetails,
            [id]: value,
          });
        }

        break;
      case "account_number":
        if (value.length > 17) {
          return;
        } else {
          setRecipientAccountDetails({
            ...recipientAccountDetails,
            [id]: value,
          });
        }

        break;
      default: {
        setRecipientAccountDetails({
          ...recipientAccountDetails,
          [id]: value,
        });
      }
    }
  };

  function returnHome() {
    setErrorIdentifier(false);
    setLoading(false);
    setViewState("default");
  }

  function guessRecipientType(test) {
    if (paymentType === "pushtoaccount") {
      return "routing_account";
    } else {
      return test.includes("@") ? "email" : "phone";
    }
  }

  function confirmIdentifier() {
    const identifierType = guessRecipientType(recipientIdentifier);
    setRecipientType(identifierType);

    if ("venmo" === paymentType && "email" === identifierType) {
      setErrorIdentifier(true);
      setLoading(false);
      return false;
    }

    if ("phone" === identifierType) {
      const parsed = phone(recipientIdentifier, { country: "USA" });
      if (!parsed.isValid) {
        setErrorIdentifier(true);
        setLoading(false);
        return false;
      }
      setRecipientIdentifier(parsed.phoneNumber);
    }

    if ("email" === identifierType) {
      const e =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!e.test(recipientIdentifier.toLowerCase())) {
        setErrorIdentifier(true);
        setLoading(false);
        return false;
      }
    }

    setErrorIdentifier(false);
    setLoading(false);
    return true;
  }

  const confirmBankAccount = () => {
    if (recipientAccountDetails.routing_number.length < 9) {
      setErrorBankRouting(true);
    } else {
      setErrorBankRouting(false);
    }
    if (recipientAccountDetails.account_number.length < 4) {
      setErrorBankAccount(true);
    } else {
      setErrorBankAccount(false);
    }
    if (
      recipientAccountDetails.routing_number.length < 9 ||
      recipientAccountDetails.account_number.length < 4
    ) {
      return false;
    }
    return true;
  };

  function getRecipientIdentifierDisplay() {
    switch (recipientIdentifier.includes("@")) {
      case false: {
        return (
          <b>
            ({recipientIdentifier.slice(-10, -7)}){" "}
            {recipientIdentifier.slice(-7, -4)}-{recipientIdentifier.slice(-4)}
          </b>
        );
      }
      default:
        return <b>{recipientIdentifier}</b>;
    }
  }

  function getPaymentTypeDisplay() {
    switch (paymentType) {
      case "paypal": {
        return <b>PayPal</b>;
      }
      case "venmo": {
        return <b>Venmo</b>;
      }
      case "pushtocard": {
        return <b>Direct to Debit Card</b>;
      }
      case "pushtoaccount": {
        return <b>Direct to Account</b>;
      }
      case "prizeoutcard": {
        return <b>Prizeout</b>;
      }
      default: {
        return "";
      }
    }
  }

  const submitTransfer = async () => {
    setLoading(true);
    setErrorIdentifier(false);
    if (confirmIdentifier()) {
      const postData = {
        payment_type: paymentType,
        recipient_type: recipientType,
        recipient_identifier: recipientIdentifier,
      };
      try {
        await api.post(
          `/external/transfer/${transferId}/${inviteId}`,
          postData
        );
        setStatusDate(new Date());
        setViewState("processing");
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
  };

  const deleteTransfer = async () => {
    setLoading(true);
    try {
      await api.delete(`/external/transfer/${transferId}/${inviteId}`);
      setStatusDate(new Date());
      setViewState("declined");
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const backOutDeleteTransfer = async () => {
    setLoading(false);
    setViewState("default");
  };

  const pollForTransferStatus = useCallback(async () => {
    try {
      const response = await api.get(
        `/external/transfer/${transferId}/${inviteId}`
      );
      if (response.data.transfer_state !== "WaitForExternalInput") {
        setTransferData(response.data);
      }
      setStatusDate(new Date(transferData.transfer_state_date));
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [api, inviteId, transferId, transferData, setTransferData]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        (viewState === "changeP2CCard" || viewState === "processing") &&
        transferData &&
        transferData.transfer_state !== "Succeeded" &&
        transferData.transfer_state !== "Failed"
      ) {
        pollForTransferStatus();
      } else if (
        transferData.transfer_state === "Succeeded" ||
        transferData.transfer_state === "Failed"
      ) {
        setFrameHeight("200px");
      }
    }, 3000);
    setTimeout(() => {
      clearInterval(interval);
    }, 600000);
    return () => clearInterval(interval);
  }, [transferData, viewState, pollForTransferStatus]);

  useEffect(() => {
    setPaymentType(transferData.payment_type);
    switch (transferData.transfer_state) {
      case "Failed": {
        setViewState("failed");
        break;
      }
      case "Succeeded": {
        setViewState("successful");
        break;
      }
      case "WaitForExternalInput": {
        setViewState("default");
        break;
      }
      case "server_down": {
        setViewState("server_down");
        break;
      }
      case undefined: {
        setViewState("not_found");
        break;
      }
      default: {
        setViewState("processing");
        break;
      }
    }
  }, [transferData]);

  const getIframeUrl = () => {
    const domain = window.location.host;
    if (domain === "localhost:5100") {
      return "http://localhost:3000";
    }
    if (domain === "receive.staging.neuralpayments.com") {
      return "https://p2c.app.staging.neuralpayments.com";
    }
    if (domain === "receive.neuralpayments.com") {
      return "https://p2c.app.neuralpayments.com";
    }
    if (domain === "np-dev-app-frontend.ngrok.io") {
      return "https://np-dev-app-frontend-cde.ngrok.io";
    }
    return "https://p2c.app.neuralpayments.com";
  };

  const iframeUrl = `${getIframeUrl()}?t=${transferId}&i=${inviteId}&off_network_bg_primary=${branding.receiving_colors.receiving_bg_primary?.slice(
    1,
    7
  )}&off_network_bg_secondary=${branding.receiving_colors.receiving_bg_secondary?.slice(
    1,
    7
  )}&off_network_button_color=${branding.receiving_colors.receiving_bg_tertiary?.slice(
    1,
    7
  )}&off_network_text_color=${branding.receiving_colors.receiving_text?.slice(1, 7)}&app=p2c&ui_version=new`;

  const TransferDetails = () => {
    return (
      <>
        <b>{transferData.sender}</b> sent <b>${transferData.amount}</b>
        {transferData.note && transferData.note.length && (
          <> with the note: "{transferData.note}"</>
        )}
        .
      </>
    );
  };

  function getFailedStateMsg() {
    if (paymentType === "pushtocard") {
      return (
        <h5>
          “On no...this card was not accepted at this time and the funds have
          been sent back to the sender. Let the sender know that they can resend
          the funds, and you can enter a different card or choose a different
          payment option.”
        </h5>
      );
    }

    return (
      <h5>
        Transfer failed on {statusDate.toLocaleDateString("en-US")} at{" "}
        {statusDate.toLocaleTimeString("en-US")}. The payment has been returned
        to the sender.
      </h5>
    );
  }

  const checkIndex = (page, index) => {
    switch (page) {
      case "homepage":
        return index > 0 && index < 10;
      case "optionspage":
        return index > 9;
      default:
        return null;
    }
  };

  const prizeout_params = {
    env: branding.prizeout_partner?.environment,
    user: {
      session_id: `${transferId}.${inviteId}`,
    },
    publisher: {
      id: branding.prizeout_partner?.partner_id,
    },
    callbacks: {
      onClose: function () {
        pollForTransferStatus();
      },
      onCashoutFail: function () {
        pollForTransferStatus();
      },
      onCashoutSuccess: function () {
        pollForTransferStatus();
      },
    },
  };

  const clickPayPal = () => {
    setPaymentType("paypal");
    setViewState("confirmPayPal");
  };

  const clickVenmo = () => {
    setPaymentType("venmo");
    if ("email" === guessRecipientType(recipientIdentifier)) {
      setViewState("changeVenmoIdentifier");
    } else {
      setViewState("confirmVenmo");
    }
  };

  const clickP2C = () => {
    setPaymentType("pushtocard");
    setViewState("changeP2CCard");
  };

  const clickP2A = () => {
    setPaymentType("pushtoaccount");
    setViewState("changeP2AAccount");
    setRecipientType("routing_account");
  };

  const clickDecline = async () => {
    setViewState("confirmDecline");
  };

  const clickPrizeout = () => {
    window.prizeoutSDK.init(prizeout_params);
  };

  const prizeoutEnabled = () => {
    if (
      branding.button_indexes.prizeout_index > 0 &&
      branding.prizeout_partner &&
      parseFloat(transferData.amount) < 500.01
    ) {
      return true;
    }
  };

  if (
    prizeoutEnabled() &&
    branding.button_indexes.prizeout_widget &&
    ((viewState === "default" &&
      checkIndex("homepage", branding.button_indexes.prizeout_index)) ||
      (viewState === "moreOptions" &&
        checkIndex("optionspage", branding.button_indexes.prizeout_index)))
  ) {
    setTimeout(() => {
      window.prizeoutSDK.create_widget(
        document.getElementById("mini-widget"),
        prizeout_params
      );
    }, 200);
  }

  const renderButtons = (page) => {
    const renderButton = (title, index, onclick, icon) => {
      if (checkIndex(page, index)) {
        return (
          <div style={{ order: index }}>
            <button
              onClick={onclick}
              style={{ order: index }}
              className={icon ? "button_with_icon" : ""}
            >
              {icon && (
                <span style={{ width: "32px", height: "32px" }}>{icon}</span>
              )}
              {title}
              {icon && <span style={{ width: "32px" }}></span>}
            </button>
          </div>
        );
      }
    };

    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <>
          {renderButton(
            "PayPal",
            branding.button_indexes.paypal_index,
            clickPayPal,
            <PayPalIcon />
          )}
        </>
        <>
          {renderButton(
            "Venmo",
            branding.button_indexes.venmo_index,
            clickVenmo,
            <VenmoIcon />
          )}
        </>
        <>
          {renderButton(
            "Direct to Debit Card",
            branding.button_indexes.p2c_index,
            clickP2C,
            <div className="icon_border">
              <CardIcon
                style={{
                  stroke: branding.receiving_colors.receiving_text,
                  fill: branding.receiving_colors.receiving_bg_primary,
                  margin: "1.5px 0 0 2.5px",
                }}
              />
            </div>
          )}
        </>
        <>
          {renderButton(
            "Direct to Account",
            branding.button_indexes.p2a_index,
            clickP2A,
            <div className="icon_border">
              <BankIcon
                style={{
                  fill: branding.receiving_colors.receiving_text,
                  margin: "1px 0 0 2.5px",
                }}
              />
            </div>
          )}
        </>
        <>
          {renderButton(
            "Decline Payment",
            branding.button_indexes.decline_index,
            clickDecline,
            false
          )}
        </>
        {prizeoutEnabled() &&
          checkIndex(page, branding.button_indexes.prizeout_index) && (
            <div
              style={{
                order: branding.button_indexes.prizeout_index,
              }}
            >
              {branding.button_indexes.prizeout_widget ? (
                <div
                  id="mini-widget"
                  style={{
                    height: "220px",
                    marginBottom: "12px",
                    borderRadius: "12px",
                    padding: "4px",
                    backgroundColor: "#ffffff",
                  }}
                ></div>
              ) : (
                <button onClick={clickPrizeout} className="button_with_icon">
                  <span style={{ width: "32px", height: "32px" }}>
                    <div className="icon_border">
                      <GiftCardIcon
                        style={{
                          fill: branding.receiving_colors.receiving_text,
                          margin: "1.5px 0 0 1.5px",
                        }}
                      />
                    </div>
                  </span>
                  Gift Card
                  <span style={{ width: "32px" }}></span>
                </button>
              )}
            </div>
          )}
        {page === "homepage" ? (
          <>
            {(branding.button_indexes.paypal_index > 9 ||
              branding.button_indexes.venmo_index > 9 ||
              branding.button_indexes.p2c_index > 9 ||
              branding.button_indexes.p2a_index > 9 ||
              branding.button_indexes.prizeout_index > 9 ||
              branding.button_indexes.decline_index > 9) && (
              <div style={{ order: 10 }}>
                <button onClick={() => setViewState("moreOptions")}>
                  More Options
                </button>
              </div>
            )}
          </>
        ) : (
          <div style={{ order: 20 }}>
            <button onClick={returnHome}>Go Back</button>
          </div>
        )}
      </div>
    );
  };

  const renderAdvertising = () => {
    const renderAdvertisingButton = () => {
      let link = branding.advertising.advertising_link;

      if (!branding.advertising.advertising_link?.includes("http")) {
        link = `https://${branding.advertising.advertising_link}`;
      }
      return (
        <>
          {branding.advertising.advertising_button_text && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <a
                href={link}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                {branding.advertising.advertising_button_text}
              </a>
            </div>
          )}
        </>
      );
    };

    return (
      <>
        {branding.advertising.advertising_description ? (
          <div className="advertising_box">
            <div>{branding.advertising.advertising_description}</div>
            {renderAdvertisingButton()}
          </div>
        ) : (
          <div style={{ margin: "40px 0" }}>{renderAdvertisingButton()}</div>
        )}
      </>
    );
  };

  const renderP2AInfo = () => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ marginRight: "5px", textAlign: "right" }}>
          <div>Routing Number:</div>
          <div>Account Number:</div>
        </div>
        <div style={{ textAlign: "left" }}>
          <div>{recipientAccountDetails.routing_number}</div>
          {
            // TODO successful or processing?
            viewState === "successful" ? (
              <div>
                {recipientAccountDetails.account_number.replace(
                  /^.{5}/g,
                  "*****"
                )}
              </div>
            ) : (
              <div>{recipientAccountDetails.account_number}</div>
            )
          }
        </div>
      </div>
    );
  };

  const renderP2AGoBack = () => {
    return (
      <div>
        <button
          onClick={() => {
            setRecipientType("");
            setRecipientIdentifier(transferData.recipient_identifier);
            setRecipientAccountDetails({
              routing_number: "",
              account_number: "",
            });
            setErrorBankAccount(false);
            setErrorBankRouting(false);
            returnHome();
          }}
        >
          Go Back
        </button>
      </div>
    );
  };

  const changeIdentifier = (state) => {
    return (
      <>
        {TransferDetails()}
        <h5>
          Please choose an alternate {getPaymentTypeDisplay()} account that can
          receive funds.
        </h5>
        <label htmlFor="recipient_identifier">
          {state === "confirmPayPal" ? "Phone number or email" : "Phone number"}
        </label>
        <input
          id="recipient_identifier"
          value={recipientIdentifier}
          onChange={(e) => handleRecipientIdentifierChange(e)}
          placeholder="Type Account Number"
          disabled={loading}
        />
        {errorIdentifier && (
          <div style={{ marginBottom: "20px" }}>
            {state === "confirmPayPal"
              ? "Please enter a valid email or phone number"
              : "Please enter a valid phone number"}
          </div>
        )}
        <button
          onClick={() => {
            if (confirmIdentifier()) {
              setViewState(state);
            }
          }}
        >
          Confirm
        </button>
        <button
          onClick={() => {
            setRecipientType("");
            setRecipientIdentifier(transferData.recipient_identifier);
            returnHome();
          }}
        >
          Go Back
        </button>
      </>
    );
  };

  const confirmType = (state) => {
    const textDisplay = () => {
      switch (state) {
        case "changePayPalIdentifier":
          return "Choose an alternate email or US phone number";
        case "changeVenmoIdentifier":
          return "Choose an alternate US phone number";
        default:
          return "";
      }
    };
    return (
      <>
        {TransferDetails()}
        <h5>
          Please confirm that your {getPaymentTypeDisplay()} account can receive
          funds sent to {getRecipientIdentifierDisplay()}.
        </h5>
        {errorIdentifier && (
          <div style={{ marginBottom: "20px" }}>
            {state === "confirmPayPal"
              ? "Please enter a valid email or phone number"
              : "Please enter a valid phone number"}
          </div>
        )}
        <button onClick={submitTransfer}>
          Send Payment via {getPaymentTypeDisplay()}
        </button>
        <button
          onClick={() => {
            setRecipientType("email");
            setViewState(state);
          }}
        >
          {textDisplay()}
        </button>
        <button onClick={returnHome}>Go Back</button>
      </>
    );
  };

  const renderScreen = () => {
    switch (true) {
      case error:
        return <>Sorry, an error has occurred.</>;
      case "successful" === viewState:
        // case "default" === viewState:
        return (
          <>
            <h5>
              {paymentType !== "pushtoaccount" ? (
                <>
                  Payment was sent to {getRecipientIdentifierDisplay()} via{" "}
                  {getPaymentTypeDisplay()}
                </>
              ) : (
                <>Transfer initiated to {renderP2AInfo()}</>
              )}{" "}
              on {statusDate.toLocaleDateString("en-US")} at{" "}
              {statusDate.toLocaleTimeString("en-US")}
            </h5>
            {renderAdvertising()}
            {paymentType !== "prizeoutcard" && (
              <>
                You may now close this window and check your account for the
                funds.
              </>
            )}
          </>
        );
      case "confirmDecline" === viewState:
        return (
          <>
            {TransferDetails()}
            <h5>
              You are choosing to decline payment. The sender will be notified
              via their banking app.
            </h5>
            <button onClick={deleteTransfer}>Confirm</button>
            <button onClick={backOutDeleteTransfer}>Go Back</button>
          </>
        );
      case "declined" === viewState:
        return (
          <>
            <h5>
              Payment was declined on {statusDate.toLocaleDateString("en-US")}{" "}
              at {statusDate.toLocaleTimeString("en-US")}. Funds will be
              returned to the sender.
            </h5>
            {renderAdvertising()}
          </>
        );
      case "failed" === viewState:
        return (
          <>
            {getFailedStateMsg()}
            {renderAdvertising()}
          </>
        );
      case "processing" === viewState:
        return (
          <>
            {TransferDetails()}
            <h5>Payment is processing...</h5>
          </>
        );
      case "not_found" === viewState:
        return <h5 className="center_page">Transfer not found.</h5>;
      case "server_down" === viewState:
        return (
          <h5 className="center_page">
            This service is currently down for maintenance. We apologize for the
            inconvenience.
          </h5>
        );
      case "changePayPalIdentifier" === viewState:
        return changeIdentifier("confirmPayPal");
      case "changeVenmoIdentifier" === viewState:
        return changeIdentifier("confirmVenmo");
      case "confirmPayPal" === viewState:
        return confirmType("changePayPalIdentifier");
      case "confirmVenmo" === viewState:
        return confirmType("changeVenmoIdentifier");
      case "confirmP2A" === viewState:
        return (
          <>
            {TransferDetails()}
            <h5>
              Please confirm that your {getPaymentTypeDisplay()} can receive
              funds sent to
              {renderP2AInfo()}
            </h5>
            <button onClick={submitTransfer}>
              Send Payment via {getPaymentTypeDisplay()}
            </button>
            <button
              onClick={() => {
                setViewState("changeP2AAccount");
              }}
            >
              Choose an alternate account
            </button>
            {renderP2AGoBack()}
          </>
        );
      case "moreOptions" === viewState:
        return (
          <>
            {TransferDetails()}
            <div style={{ marginTop: "40px" }}>
              {renderButtons("optionspage")}
            </div>
          </>
        );
      case "changeP2CCard" === viewState:
        return (
          <>
            {TransferDetails()}
            <h5>Receive with Debit Card</h5>
            <iframe
              title={"debit-card-input-iframe"}
              id="cde-iframe"
              style={{ border: "0", marginTop: "-20px" }}
              width={"100%"}
              height={frameHeight}
              src={iframeUrl}
              allow=""
            ></iframe>
            <button id="go-back-from-p2c" onClick={returnHome}>
              Go Back
            </button>
          </>
        );
      case "changeP2AAccount" === viewState:
        return (
          <>
            {TransferDetails()}
            <h5>Transfer to Account</h5>
            <label htmlFor="routing_number">Routing Number</label>
            <input
              id="routing_number"
              placeholder="Type Routing Number"
              value={recipientAccountDetails.routing_number}
              onChange={(e) => handleRecipientAccountDetailsChange(e)}
              required
              type="tel"
              // disabled={loading}
            />

            {errorBankRouting && (
              <div style={{ marginBottom: "20px" }}>
                Please enter a valid routing number
              </div>
            )}
            <label htmlFor="routing_number">Account Number</label>
            <input
              id="account_number"
              placeholder="Type Account Number"
              value={recipientAccountDetails.account_number}
              onChange={(e) => handleRecipientAccountDetailsChange(e)}
              required
              type="tel"
              disabled={loading}
            />
            {errorBankAccount && (
              <div style={{ marginBottom: "20px" }}>
                Please enter a valid account number
              </div>
            )}
            <button
              onClick={() => {
                if (confirmBankAccount()) {
                  setViewState("confirmP2A");
                  setRecipientIdentifier(
                    recipientAccountDetails.routing_number +
                      "_" +
                      recipientAccountDetails.account_number
                  );
                }
              }}
            >
              Confirm
            </button>
            {renderP2AGoBack()}
          </>
        );
      default:
        return (
          <>
            {TransferDetails()}
            <h5>
              Payment will be sent to {getRecipientIdentifierDisplay()} via your
              choice of the service below.
            </h5>
            {renderButtons("homepage")}
          </>
        );
    }
  };

  return (
    <main>
      <nav>
        <div className="logo"></div>
      </nav>
      {renderScreen()}
    </main>
  );
};

export default ExternalTransfer;
