import { useMemo } from "react";
import axios from "axios";

function useAPI() {
  const baseUrl = process.env.REACT_APP_API_URL;

  const httpRequest = useMemo(
    () =>
      axios.create({
        baseURL: baseUrl,
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    [baseUrl]
  );

  let api = httpRequest;

  return api;
}

export default useAPI;
